



















































































































































































import { createLesson, updateLesson } from "@/api/lessonService";
import { LOCATIONS } from "@/helpers/lessonUtils";
import { stringTimeToUnix, timestampRangeToHours } from "@/helpers/timeUtils";
import { useNotifier } from "@/providers/notifier";
import Moment from "@/services/moment";
import { uiModule } from "@/store/modules/ui";
import { userModule } from "@/store/modules/user";
import {
  computed,
  defineComponent,
  inject,
  PropType,
  reactive,
  ref,
  watchEffect,
} from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

const emptyLesson: Learnlink.Lesson.Create = {
  customerUID: "",
  endTime: 0,
  location: LOCATIONS.ONLINE,
  projectID: "",
  sellerUID: "",
  startTime: 0,
};

export default defineComponent({
  name: "RegisterLessonDialog",
  props: {
    lesson: {
      type: Object as PropType<Learnlink.Lesson.FullViewApp>,
      default: () => emptyLesson,
    },
    projects: {
      type: Array as PropType<Learnlink.Project.FullViewApp[]>,
      required: true
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const notify = useNotifier();
    const getLessons = inject<() => Promise<void>>("getLessons");

    const vm = reactive({
      date: props.lesson.ID ? Moment.unix(props.lesson.startTime).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
      endTime: props.lesson.ID ? Moment.unix(props.lesson.endTime).format("HH:mm") : "",
      loading: false,
      location: LOCATIONS.ONLINE,
      name: props.lesson.project?.studentName || "",
      projectID: "",
      repeat: 0,
      startTime: props.lesson.ID ? Moment.unix(props.lesson.startTime).format("HH:mm") : "",
      step: props.lesson.ID ? 2 : 1,
    });

    const projectPickerItems = computed(() => {
      if (props.projects) {
        return props.projects.map((project) => {
          return {
            text: project.studentName
              ? project.studentName
              : `${project.customer.profile.firstName} ${project.customer.profile.lastName}`,
            value: project.ID,
          };
        });
      }
      return [];
    });

    const endTime = computed(() => stringTimeToUnix(vm.date, vm.endTime));
    const startTime = computed(() => stringTimeToUnix(vm.date, vm.startTime));
    const hours = computed(() => {
      const definition = endTime.value - startTime.value <= 4500 ? "time" : "timer";
      return `${timestampRangeToHours(startTime.value, endTime.value)} ${definition}`;
    });
    const readableDate = computed(() => Moment(vm.date).format("dddd Do MMMM"));
    const invalidTime = computed(() => vm.startTime >= vm.endTime);
    const allowedEndTimeHours = (value: number) => {
      if (vm.startTime) return value >= +vm.startTime.split(":")[0];
      return value > 0;
    };
    const allowedEndTimeMinutes = (value: number) => value % 5 === 0;
    const allowedStartTimeMinutes = (value: number) => value % 5 === 0;

    function pickProject(projectID: string, name: string) {
      vm.name = name;
      vm.projectID = projectID;
      vm.step++;
    }

    function cancel() {
      _value.value = false;
    }

    async function create() {
      vm.loading = true;

      try {
        const customerUID = props.projects.filter(
          (p) => p.ID === vm.projectID
        )[0].customerUID;

        const projectID = vm.projectID;

        if (customerUID && projectID) {
          await createLesson({
            customerUID,
            endTime: endTime.value,
            location: vm.location,
            projectID,
            sellerUID: userModule.state.userId,
            startTime: startTime.value,
          }, vm.repeat);

          uiModule.actions.fetchIncompleteReportsCount();

          if (getLessons) await getLessons();

          const event = new Event("refreshReports");
          document.dispatchEvent(event);

          notify({
            title: "Registrert 🥳",
            message: "Timen ble registrert",
            type: "success",
          });

          reset();
        }
        else {
          notify({
            title: "Wops 🤯",
            message: "Noe mangler for å kunne opprette time",
            type: "error",
          });
          vm.loading = false;
        }
      }
      catch (e) {
        notify({
          title: "Klarte ikke lagre timen 🤯",
          message: "Kontakt support hvis problemet vedvarer.",
          type: "error",
        });
        vm.loading = false;
      }
    }

    async function update() {
      vm.loading = true;

      try {
        await updateLesson({
          ID: props.lesson.ID,
          endTime: endTime.value,
          startTime: startTime.value
        });

        notify({
          title: "Oppdatert 🥳",
          message: "Timen ble Oppdatert",
          type: "success",
        });

        if (getLessons) await getLessons();

        const event = new Event("refreshReports");
        document.dispatchEvent(event);

        reset();
      }
      catch (e) {
        notify({
          title: "Klarte ikke lagre timen 🤯",
          message: "Kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    function reset() {
      vm.step = 1;
      vm.name = "";
      vm.date = "";
      vm.startTime = "";
      vm.endTime = "";
      vm.loading = false;
      _value.value = false;
    }

    return {
      _value,
      allowedEndTimeHours,
      allowedEndTimeMinutes,
      allowedStartTimeMinutes,
      cancel,
      create,
      hours,
      invalidTime,
      pickProject,
      projectPickerItems,
      readableDate,
      timestampRangeToHours,
      update,
      vm,
    };
  },
});
