





















































import { defineComponent, onMounted, provide, reactive } from "@vue/composition-api";
import { getActiveProjects } from "@/api/projectService";
import { getLessons } from "@/api/lessonService";
import { getMyTeams } from "@/api/teamService";
import { LOCATIONS } from "@/helpers/lessonUtils";
import { userModule } from "@/store/modules/user";
import Calendar from "@/components/Calendar.vue";
import CancelLessonDialog from "@/components/lessons/CancelLessonDialog.vue";
import Learnlink from "@learnlink/interfaces";
import LessonCardSeller from "@/components/lessons/LessonCardSeller.vue";
import RegisterLessonDialog from "@/components/lessons/RegisterLessonDialog.vue";
import useAsyncData from "@/hooks/useAsyncData";

const emptyLesson: Learnlink.Lesson.Create = {
  customerUID: "",
  endTime: 0,
  location: LOCATIONS.ONLINE,
  projectID: "",
  sellerUID: "",
  startTime: 0,
};

export default defineComponent({
  name: "LessonsSeller",
  components: { Calendar, RegisterLessonDialog, CancelLessonDialog, LessonCardSeller },
  setup() {
    const vm = reactive({
      lesson: {
        ID: ""
      } as Learnlink.Lesson.FullViewApp,
      projects: [] as Learnlink.Project.FullViewApp[],
      showRegisterLessonDialog: false,
      showCancelLessonDialog: false,
      showLessonCard: false,
      teams: [] as Learnlink.Team.FullMetadata[],
    });

    const { isLoading, data: lessons, refresh } = useAsyncData(() =>
      getLessons("seller", userModule.state.userId)
    );

    onMounted(async() => {
      vm.projects = await getActiveProjects("seller", userModule.state.userId);
      vm.teams = await getMyTeams(userModule.state.userId);
    });

    function showLessonCard(lesson: Learnlink.Lesson.FullViewApp): void {
      vm.lesson = lesson;
      vm.showLessonCard = true;
    }

    function showEditLessonDialog(lesson: Learnlink.Lesson.FullViewApp): void {
      vm.lesson = lesson;
      vm.showRegisterLessonDialog = true;
      vm.showLessonCard = false;
    }

    function showRegisterLessonDialog(): void {
      vm.lesson = emptyLesson as Learnlink.Lesson.FullViewApp;
      vm.showRegisterLessonDialog = true;
      vm.showLessonCard = false;
    }

    function showCancelLessonDialog(lesson: Learnlink.Lesson.FullViewApp): void {
      vm.lesson = lesson;
      vm.showCancelLessonDialog = true;
      vm.showLessonCard = false;
    }

    provide("getLessons", refresh);
    provide("showEditLessonDialog", showEditLessonDialog);
    provide("showCancelLessonDialog", showCancelLessonDialog);
    provide("showLessonCard", showLessonCard);

    return {
      isLoading,
      lessons,
      showEditLessonDialog,
      showRegisterLessonDialog,
      vm,
    };
  },
});
